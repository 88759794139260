import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
  ContentCard,
  Button,
} from "../components"
import { FixedPageH1, FixedPageH2 } from "../elements"
import { graphql, Link } from "gatsby"

const altarPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="お仏壇の移動・処分について"
        pagedesc="愛知県（弥富）のマルスエ佛壇の移動・処分ページです。"
        pagepath={location.pathname}
        pageimg={data.service001.childImageSharp.original.src}
        pageimgw={data.service001.childImageSharp.original.width}
        pageimgh={data.service001.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.service001.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>
              お仏壇移動・処分サービス
            </h1>
          </FixedPageH1>
          <Content>
            <div id="service"></div>
            <FixedPageH2>サービス一覧</FixedPageH2>
            <ContentCard
              fluid={data.moving.childImageSharp.fluid}
              title="お仏壇の移動・処分・お預かり"
              cover="Service"
            >
              <div>
                <Button href="/obutsudan-idou-shobun/">移動・処分・お預かりへ</Button>
              </div>
            </ContentCard>
            <ContentCard
              fluid={data.goldIngot.childImageSharp.fluid}
              title="金リサイクル処分"
              cover="Recycle"
            >
              <div>
                <Button href="/gold-recycle/">
                  金リサイクル処分へ
                </Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    service001: file(relativePath: { eq: "service001.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    service001: file(relativePath: { eq: "service001.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    osentakuSample002: file(relativePath: { eq: "osentaku-sample002.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    after001: file(relativePath: { eq: "after001.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    service001: file(relativePath: { eq: "service001.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goldIngot: file(relativePath: { eq: "gold-ingot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    moving: file(relativePath: { eq: "moving.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kobutsuzenshu: file(relativePath: { eq: "kobutsuzenshu.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sandaimeFixed: file(relativePath: { eq: "sandaimeFixed.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kanrisha: file(relativePath: { eq: "kanrishaSagyou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default altarPage
